body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  margin-bottom: 10%;
  padding: 0 0 0 0 !important;
}

.chart {
  width: 1080px;
  height: 350px;
  /* margin-top: 150px !important; */
}

.list {
  width: 1080px;
  height: 800px;
  margin-top: 300px !important;
}

.traffic {
  margin-top: 15px !important;
}

.ddos {
  margin-top: 200px !important;
}

.title {
  margin-top: 15px !important;
  font-weight: bolder;
}